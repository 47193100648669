@media screen and (min-width: 1280px) and (max-width: 1599px) {
    .howItWorks {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .container {
        width: 1220px;
        margin: 0 auto;
    }

    .howItWorks__content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .howItWorks__content h2 {
        line-height: 80%;
    }

    .howItWorks__labels-block {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 30px;
        gap: 10px;
    }

    .howItWorks__label {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        padding-left: 15px;
        padding-right: 15px;
        color: #7A7A7A;
        font-size: 20px;
        line-height: 100%;
        background-color: #EEEEEE;
        border-radius: 18px;
        cursor: pointer;
    }

    .howItWorks__slider {
        position: relative;
        width: 100%;
    }

    .howItWorks__slider-arrows {
        position: absolute;
        display: flex;
        gap: 10px;
        z-index: 2;
        top: 60px;
        right: 60px;
    }
}