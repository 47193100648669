@media screen and (min-width: 470px) and (max-width: 1023px) {
    .howItWorks {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .container {
        width: 93%;
        margin: 0 auto;
    }

    .howItWorks__content {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .howItWorks__content h2 {
        font-size: 58px;
        line-height: 80%;
        max-width: 275px;
        text-align: center;
    }

    .howItWorks__labels-block {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 20px;
        gap: 5px;
    }

    .howItWorks__label {
        border: none;
        height: 31px;
        padding-left: 10px;
        padding-right: 10px;
        color: #7A7A7A;
        font-size: 16px;
        line-height: 100%;
        background-color: #EEEEEE;
        border-radius: 18px;
        cursor: pointer;
    }

    .howItWorks__slider {
        position: relative;
        width: 100%;
    }

    .howItWorks__slider-arrows {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;
    }
}