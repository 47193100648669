@media screen and (min-width: 320px) and (max-width: 1023px) {
    .header {
        position: sticky;
        top: 10px;
        margin-bottom: 10px;
        z-index: 100;
    }

    .container {
        width: 93%;
        margin: 0 auto;
    }

    .header__logo {
        width: 136px;
        height: 30px;
    }

    /* .header__menuIcon {
        background: url('../../Media/MenuIcon.svg') center no-repeat;
        background-size: contain;
        width: 28px;
        height: 16px;
    } */

    .header__menuIcon {
        z-index: 100;
        display: block;
        position: relative;
        width: 30px;
        height: 18px;
        cursor: pointer;
        float: right;

    }

    .header__menuIcon span,
    .header__menuIcon::before,
    .header__menuIcon::after {
        left: 0;
        position: absolute;
        /* height: 10%; */
        height: 15%;
        border-radius: 10px;
        width: 100%;
        
        transition: all 0.3s ease 0s;
        background-color: #131313;
    }

    .header__menuIcon::before,
    .header__menuIcon::after {
        content: "";
    }

    .header__menuIcon::before {
        top: 0;
    }

    .header__menuIcon::after {
        bottom: 0;
    }

    .header__menuIcon span {
        top: 50%;
        transform: scale(1) translate(0px, -50%);
    }

    .header__menuIcon._active span {
        transform: scale(0) translate(0px, -50%);
    }

    .header__menuIcon._active::before {
        top: 61%;
        transform: rotate(-45deg) translate(0px, -61%);
    }

    .header__menuIcon._active::after {
        top: 49%;
        transform: rotate(45deg) translate(0px, 49%);
    }

    .header__menu {
        display: none;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #fff;
        z-index: 99;
    }

    .header__navbar {
        height: 70px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 20px;
    }

    .header__menu-list {
        display: none;
        gap: 40px;
        font-size: 20px;
    }

    .header__menu-list li {
        display: flex;
        align-items: center;
        gap: 10px;
        transition: color 0.1s linear;
    }

    .header__menu-dot {
        height: 10px;
        width: 10px;
        border: 1px solid #131313;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #fff;
        transition: background-color 0.1s linear;
    }

    .mobile__navbar {
        position: fixed;
        top: -100%;
        height: 100%;
        width: 100%;
        background-color: #fff;
        transition: top 0.3s ease 0s;
        z-index: 99;
        overflow: auto;
    }

    .mobile__navbar_active {
        top: 0;
    }

    .mobile__menu {
        margin-top: 160px;
    }

    .mobile__menu-list {
        padding: 0;
    }

    .mobile__menu-list li {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color 0.1s linear;
        font-family: 'Bebas Neue';
        font-size: 40px;
        line-height: 90%;
        margin-bottom: 50px;
    }

    .mobile__menu-list li a {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .mobile__menu-list li .header__menu-dot {
        display: none;
    }

    .mobile__menu-list li > .active {
        color: #131313;
    }

    .mobile__menu-list li > .active .header__menu-dot {
        display: block;
        background-color: #131313;
    }
}