@media screen and (min-width: 320px) and (max-width: 1023px) {
    .container {
        width: 93%;
        margin: 0 auto;
    }

    .mainBanner__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
    }

    .mainBanner__iphoneMockup {
        width: 334px;
        height: 675px;
        border-radius: 21px;
        background: url('../../Media/MainBanneriPhoneMobile.png') center no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 50;
        position: relative;
    }

    .mainBanner__title {
        color: #fff;
        font-size: 58px;
        line-height: 90%;
        max-width: 232px;
        text-align: center;
        margin-top: 80px;
    }

    .mainBanner__hashtags-block {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mainBanner__hashtag {
        height: 37px;
        padding-left: 17px;
        padding-right: 17px;
        background-color: #fff;
        border: 1px solid #131313;
        border-radius: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #131313;
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 5px;
    }

    .mainBanner__like-btn {
        position: absolute;
        height: 100%;
        width: 101px;
        right: 20px;
        bottom: 30px;
        display: flex;
        justify-content: center;
    }

    .ar-hashtag {
        width: 157px;
    }

    .mainBanner__phone-shadow {
        height: 675px;
        width: 334px;
        border-radius: 58px;
        background: #9A9A9A;
        opacity: 0.4;
        filter: blur(7.5px);
        margin-top: -640px;
        margin-left: 20px;
    }
}