@media screen and (min-width: 1280px) and (max-width: 1599px) {
    .contacts {
        padding-top: 50px;
        padding-bottom: 40px;
    }

    .container {
        width: 1480px;
        margin: 0 auto;
    }

    .contacts__content {
        padding-left: 50px;
        width: 100%;
        display: flex;
        /* justify-content: space-between; */
    }

    .contacts__content h2 {
        line-height: 80%;
    }

    .contacts__list {
        display: flex;
        flex-direction: column;
        margin-left: 235px;
        /* width: 400px; */
    }
}