@import url('./ContactsCard_mobile.css');


@media screen and (min-width: 1023px) {
   .contactCard {
        display: flex;
        margin-bottom: 47px;
    }

    .contactCard__avatar {
        display: block;
        height: 130px;
        width: 130px;
        border-radius: 30px;
    }

    .contactCard__avatar-block {
        width: 160px;
        height: 160px;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
    }

    .contactCard__inf h3 {
        line-height: 90%;
        font-size: 60px;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .contactCard__inf h4{
        font-family: 'Helvetica Neue';
        color: #7A7A7A;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    .no-avatar {
        height: 130px;
        width: 130px;
        background-color: #131313;
        border-radius: 30px;
    }
}