@media screen and (min-width: 1280px) and (max-width: 1599px) {
    .whatIsArtwork {
        padding: 50px 60px 50px 55px;
    }

    .whatIsArtwork__content {
        display: flex;
        justify-content: space-between;
        padding-left: 85px;
        padding-right: 50px;
    }

    .whatIsArtwork__content h2 {
        font-size: 100px;
        line-height: 80%;
    }

    .whatIsArtwork__content p {
        font-size: 30px;
        line-height: 100%;
        max-width: 670px;
    }
}