@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .container {
        width: 1004px;
        margin: 0 auto;
    }

    .footer__content {
        height: 100px;
        background-color: #131313;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 30px;
        padding-left: 60px;
        padding-right: 60px;
        color: #fff;
        font-size: 20px;
        line-height: 100%;
        margin-bottom: 20px;
    }

    .footer__powered {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
    }

    .footer__uwalk-logo {
        height: 50px;
        width: 50px;
    }

    .footer__links {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .footer__link {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
    }
}