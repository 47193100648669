@import url("./Header_1280.css");
@import url("./Header_1024.css");
@import url("./Header_mobile.css");


@media screen and (min-width: 1600px) {
    .header {
        position: sticky;
        top: 10px;
        margin-bottom: 10px;
        z-index: 100;
    }

    .container {
        width: 1480px;
        margin: 0 auto;
    }

    .header__logo {
        width: 179px;
        height: 40px;
    }

    .header__navbar {
        height: 100px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding-left: 60px;
        padding-right: 60px;
        border-radius: 30px;
    }

    .header__menuIcon {
        display: none;
    }

    .header__menu-list {
        display: flex;
        gap: 40px;
        font-size: 20px;
    }

    .header__menu-list li {
        transition: color 0.1s linear;
        cursor: pointer;
    }

    .header__menu-list li a {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .header__menu-dot {
        height: 10px;
        width: 10px;
        border: 1px solid #131313;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #fff;
        transition: background-color 0.2s linear;
    }

    .header__menu-list li:active {
        color: #131313;
    }

    .header__menu-list li:hover .header__menu-dot{
        background-color: #131313;
    }

    .header__menu-list li > .active {
        color: #131313;
    }

    .header__menu-list li > .active .header__menu-dot {
        background-color: #131313;
    }
}