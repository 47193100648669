@media screen and (min-width: 632px) and (max-width: 1023px) {
    .contacts {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .container {
        width: 93%;
        margin: 0 auto;
    }

    .contacts__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contacts__content h2 {
        font-size: 58px;
        line-height: 80%;
    }

    .contacts__list {
        display: flex;
        gap: 3vw;
        margin-top: 40px;
    }
}