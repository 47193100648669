@media screen and (min-width: 320px) and (max-width: 1023px) {
    .contactCard {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 220px;
        margin-bottom: 36px;
    }

    .contactCard__avatar {
        display: block;
        height: 90px;
        width: 90px;
        border-radius: 21px;
    }

    .contactCard__avatar-block {
        width: 110px;
        height: 110px;
        border-radius: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contactCard__inf {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .contactCard__inf h3 {
        font-size: 40px;
        line-height: 90%;
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
    }

    .contactCard__inf h4{
        font-family: 'Helvetica Neue';
        color: #7A7A7A;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 14px;
        order: 1;
    }

    .no-avatar {
        height: 90px;
        width: 90px;
        background-color: #131313;
        border-radius: 21px;
    }
}