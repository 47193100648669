@import url('./SwitchButton_mobile.css');


@media screen and (min-width: 1024px) {
    .switchButton {
        height: 76px;
        width: 76px;
        border: none;
        border-radius: 50%;
        background-color: #131313;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .btnBlocked {
        opacity: 30%;
        cursor: default;
    }

    .switchButton__arrow {
        height: 18px;
        width: 19px;
    }

    .arrow-right {
        background: url('../../Media/ArrowRight.svg') center no-repeat;
        background-size: contain;
    }

    .arrow-left {
        background: url('../../Media/ArrowLeft.svg') center no-repeat;
        background-size: contain;
    }
}
