@font-face {
    font-family: "Bebas Neue";
    src: local("Bebas Neue Bold"), local("Bebas-Neue-Bold"),
      url("../Fonts/BebasNeue/BebasNeueBold.woff2") format("woff2"),
      url("../Fonts/BebasNeue/BebasNeueBold.woff") format("woff"),
      url("../Fonts/BebasNeue/BebasNeueBold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue Roman"), local("Helvetica-Neue-Roman"),
      url("../Fonts/HelveticaNeue/HelveticaNeueCyr-Roman.woff2") format("woff2"),
      url("../Fonts/HelveticaNeue/HelveticaNeueCyr-Roman.woff") format("woff"),
      url("../Fonts/HelveticaNeue/HelveticaNeueCyr-Roman.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}