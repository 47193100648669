@media screen and (min-width: 320px) and (max-width: 1023px) {
    .howItWorksSlide {
        width: 100%;
        height: 604px;
        border-radius: 40px;
        padding: 40px 0px 0px 0px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .howItWorksSlide__title {
        font-size: 58px;
        line-height: 90%;
        text-align: center;
    }

    .howItWorksSlide__instruction {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 40px;
        max-width: 315px;
    }

    .howItWorksSlide__instruction-item:first-child {
        margin-bottom: 62px;
    }

    .howItWorksSlide__instruction-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hiws-active {
        order: 1;
    }

    .howItWorksSlide__instruction-item img {
        display: block;
        height: 90px;
        width: 90px;
        margin-bottom: 15px;
    }

    .howItWorksSlide__instruction-item h4 {
        font-size: 20px;
        line-height: 90%;
        color: #131313;
        text-align: center;
        white-space: nowrap;
        max-width: 170px;
        height: 54px;
    }

    .dottedArrowRight {
        background: url('../../../Media/DottedArrRight.svg') center no-repeat;
        background-size: contain;
        height: 11px;
        width: 32px;
        margin-top: 60px;
    }

    .first-arr {
        position: absolute;
        margin: 0;
        top: 50px;
    }
    
    .second-arr {
        position: absolute;
        margin: 0;
        top: 185px;
        transform: rotate(90deg);
        right: 65px;
    }

    .third-arr {
        position: absolute;
        top: 250px;
        margin: 0;
        transform: rotate(180deg);
    }

    .howItWorksSlide__instruction_m {
        display: flex;
        justify-content: space-around;
        position: relative;
        width: 319px;
        margin-top: 40px;
    }
}