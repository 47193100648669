.App {
  background-color: #fff;
  font-family: 'Helvetica Neue';
  font-weight: 400;
  color: #7A7A7A;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  font-family: 'Bebas Neue';
  margin: 0;
  padding: 0;
  font-size: 100px;
  font-weight: 700;
  color: #131313;
}

ul {
  list-style: none;
}

img {
  user-select: none;
}