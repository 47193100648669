@import url("./Contacts_1280.css");
@import url("./Contacts_1024.css");
@import url("./Contacts_768.css");
@import url("./Contacts_mobile.css");


@media screen and (min-width: 1600px) {
    .contacts {
        padding-top: 50px;
        padding-bottom: 40px;
    }

    .container {
        width: 1480px;
        margin: 0 auto;
    }

    .contacts__content {
        padding-left: 55px;
        width: 100%;
        display: flex;
    }

    .contacts__content h2 {
        line-height: 80%;
    }

    .contacts__list {
        display: flex;
        flex-direction: column;
        margin-left: 359px;
    }
}