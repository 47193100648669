@media screen and (min-width: 320px) and (max-width: 1023px) {
    .whatIsArtwork {
        padding-top: 60px;
        padding-bottom: 35px;
    }

    .container {
        width: 93%;
        margin: 0 auto;
    }

    .whatIsArtwork__content {
        max-width: 88%;
        text-align: center;
        margin: 0 auto;
    }

    .whatIsArtwork__content h2 {
        font-size: 58px;
        line-height: 80%;
        margin-bottom: 40px;
    }

    .whatIsArtwork__text {
        font-size: 20px;
        line-height: 100%;
    }
}