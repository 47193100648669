@import url("./Like_1280.css");
@import url("./Like_1024.css");
@import url("./Like_mobile.css");


@media screen and (min-width: 1600px) {

    .like__container {
        position: relative;
        height: 100%;
        width: 155px;
        display: flex;
        justify-content: center;
    }

    .like-btn {
        position: absolute;
        height: 120px;
        width: 120px;
        border-radius: 90px;
        background-color: #FF9369;
        bottom: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        cursor: pointer;
        z-index: 51;
    }

    .like-btn img {
        height: 32px;
        width: 38px;
    }

    .like-btn:hover {
        height: 140px;
        width: 140px;
    }

    .like-btn:active {
        height: 130px;
        width: 130px;
    }

    .like-btn-mission {
        height: 66px;
        width: 66px;
    }

    .like-btn-mission img {
        height: 15px;
        width: 18px;
    }

    .like-btn-mission:hover {
        height: 86px;
        width: 86px;
    }

    .like-btn-mission:active {
        height: 76px;
        width: 76px;
    }

    .like {
        background: url('../../Media/Like.svg') center no-repeat;
        background-size: contain;
        height: 24px;
        width: 29px;
        position: absolute;
        opacity: 0;
        transform: translate(-50%, -50%)
    }

    .like1 {
        bottom: 80px;
        left: 30px;
    }

    .like2 {
        bottom: 80px;
        left: 40px;
    }

    .like3 {
        bottom: 80px;
        left: 50px;
    }

    .like4 {
        bottom: 80px;
        left: 73px;
    }

    .like5 {
        bottom: 80px;
        left: 110px;
    }

    .like6 {
        bottom: 80px;
        left: 100px;
    }

    .like7 {
        bottom: 115px;
        left: 60px;
    }

    .animation-like1 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 600ms;
    }

    .animation-like2 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
    }

    .animation-like3 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 250ms;
    }

    .animation-like4 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 700ms;
    }

    .animation-like5 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 350ms;
    }

    .animation-like6 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 100ms;
    }

    .animation-like7 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 400ms;
    }

    @keyframes likes1 {
        0%{
            bottom: 80px;
            height: 24px;
            width: 29px;
            opacity: 100%;
        }
        90%{
            bottom: 420px;
            width: 42px;
            height: 35px;
            opacity: 100%;
        }
        98%{
            bottom: 450px;
            width: 60px;
            height: 50px;
            opacity: 20%;
        }
        100%{
            bottom: 455px;
            width: 88px;
            height: 73px;
            opacity: 0%;
        }
    }

}