@media screen and (min-width: 320px) and (max-width: 1023px) {
    .container {
        width: 93%;
        margin: 0 auto;
    }

    .footer__content {
        min-height: 100px;
        background-color: #131313;
        border-radius: 20px;
        padding: 20px 20px 24px 20px;
        color: #fff;
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap-reverse;
    }

    .footer__rights {
        width: 50%;
    }

    .footer__powered img {
        display: block;
    }

    .footer__powered {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 36px;
    }

    .footer__uwalk-logo {
        height: 50px;
        width: 50px;
        margin-bottom: 10px;
    }

    .footer__links {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 10px;
    }

    .footer__powered {
        width: 100%;
    }

    .footer__link {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
    }
}