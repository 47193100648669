@media screen and (min-width: 320px) and (max-width: 1023px) {

    .like__container {
        position: relative;
        height: 100%;
        width: 101px;
        display: flex;
        justify-content: center;
    }
    
    .like-btn {
        position: absolute;
        height: 66px;
        width: 66px;
        border-radius: 90px;
        background-color: #FF9369;
        bottom: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        cursor: pointer;
        z-index: 51;
    }
    
    .like-btn img {
        height: 15px;
        width: 18px;
    }
    
    .like-btn:hover {
        height: 86px;
        width: 86px;
    }
    
    .like-btn:active {
        height: 76px;
        width: 76px;
    }

    .like-btn-mission {
        height: 50px;
        width: 50px;
    }

    .like-btn-mission img {
        height: 15px;
        width: 18px;
    }

    .like-btn-mission:hover {
        height: 70px;
        width: 70px;
    }

    .like-btn-mission:active {
        height: 60px;
        width: 60px;
    }
    
    .like {
        background: url('../../Media/Like.svg') center no-repeat;
        background-size: contain;
        height: 24px;
        width: 29px;
        position: absolute;
        opacity: 0;
        transform: translate(-50%, -50%)
    }
    
    .like1 {
        bottom: 80px;
        left: 30px;
    }
    
    .like2 {
        bottom: 80px;
        /* left: 40px; */
        left: 35px;
    }
    
    .like3 {
        bottom: 80px;
        /* left: 50px; */
        left: 40px;
    }
    
    .like4 {
        bottom: 80px;
        /* left: 73px; */
        left: 48px;
    }
    
    .like5 {
        bottom: 80px;
        /* left: 110px; */
        left: 62px;
    }
    
    .like6 {
        bottom: 80px;
        /* left: 100px; */
        left: 56px;
    }
    
    .like7 {
        bottom: 115px;
        /* left: 60px; */
        left: 45px;
    }
    
    .animation-like1 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 600ms;
    }
    
    .animation-like2 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
    }
    
    .animation-like3 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 250ms;
    }
    
    .animation-like4 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 700ms;
    }
    
    .animation-like5 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 350ms;
    }
    
    .animation-like6 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 100ms;
    }
    
    .animation-like7 {
        animation-name: likes1;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-delay: 400ms;
    }
    
    @keyframes likes1 {
        0%{
            bottom: 40px;
            height: 14px;
            width: 16px;
            opacity: 1;
        }
        80%{
            bottom: 220px;
            width: 24px;
            height: 19px;
            opacity: 1;
        }
        90%{
            bottom: 250px;
            width: 34px;
            height: 24px;
            opacity: 0.2;
        }
        100%{
            bottom: 255px;
            width: 49px;
            height: 41px;
            opacity: 0;
        }
    }
}