@import url('./HowItWorksSlide_1024.css');
@import url('./HowItWorksSlide_mobile.css');


@media screen and (min-width: 1280px) {
    .howItWorksSlide {
        width: 100%;
        height: 611px;
        border-radius: 60px;
        padding: 60px 50px 0px 50px;
        box-sizing: border-box;
    }

    .howItWorksSlide__title {
        line-height: 90%;
        max-width: 682px;
    }

    .howItWorksSlide__instruction {
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
    }

    .howItWorksSlide__instruction-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 265px;
    }

    .howItWorksSlide__instruction-item img {
        display: block;
        height: 140px;
        width: 140px;
        margin-bottom: 40px;
    }

    .howItWorksSlide__instruction-item h4 {
        font-size: 30px;
        line-height: 90%;
        color: #131313;
        text-align: center;
        white-space: nowrap;
    }

    .dottedArrowRight {
        background: url('../../../Media/DottedArrRight.svg') center no-repeat;
        background-size: contain;
        height: 19px;
        width: 52px;
        margin-top: 60px;
    }
}