@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .container {
        width: 1004px;
        margin: 0 auto;
    }

    .mainBanner__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mainBanner__iphoneMockup {
        width: 100%;
        height: 496px;
        background: url('../../Media/MainBanneriPhone.png') center no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        z-index: 50;
        position: relative;
    }

    .mainBanner__like-btn {
        position: absolute;
        height: 100%;
        width: 101px;
        right: 55px;
        bottom: 45px;
        display: flex;
        justify-content: center;
    }

    .mainBanner__title {
        color: #fff;
        font-size: 80px;
        line-height: 80%;
        max-width: 452px;
        text-align: center;
    }

    .mainBanner__hashtags-block {
        display: flex;
        gap: 10px;
    }

    .mainBanner__hashtag {
        height: 44px;
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
        background-color: #fff;
        border: 1px solid #131313;
        border-radius: 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: #131313;
        font-size: 25px;
        line-height: 100%;
    }

    .mainBanner__phone-shadow {
        height: 478px;
        width: 875px;
        border-radius: 100px;
        background: #000000;
        opacity: 0.4;
        filter: blur(17px);
        margin-top: -429px;
    }
}