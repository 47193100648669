@import url(./Vendor/Fonts/fonts.css);

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}