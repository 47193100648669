@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .whatIsArtwork {
        padding: 50px 60px 35px 55px;
    }

    .whatIsArtwork__content {
        display: flex;
        justify-content: space-between;
        padding-left: 50px;
        padding-right: 50px;
    }

    .whatIsArtwork__content h2 {
        font-size: 80px;
        line-height: 80%;
    }

    .whatIsArtwork__content p {
        font-size: 25px;
        line-height: 100%;
        max-width: 442px;
    }
}