@media screen and (min-width: 320px) and (max-width: 1023px) {
    .switchButton {
        height: 54px;
        width: 54px;
        border: none;
        border-radius: 50%;
        background-color: #131313;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .btnBlocked {
        opacity: 30%;
        cursor: default;
    }
    
    .switchButton__arrow {
        height: 18px;
        width: 19px;
    }
    
    .arrow-right {
        background: url('../../Media/ArrowRight.svg') center no-repeat;
        background-size: contain;
    }
    
    .arrow-left {
        background: url('../../Media/ArrowLeft.svg') center no-repeat;
        background-size: contain;
    }
}