@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .contacts {
        padding-top: 50px;
        padding-bottom: 40px;
    }

    .container {
        width: 1480px;
        margin: 0 auto;
    }

    .contacts__content {
        padding-left: 45px;
        width: 100%;
        display: flex;
        /* justify-content: space-between; */
    }

    .contacts__content h2 {
        font-size: 80px;
        line-height: 80%;
    }

    .contacts__list {
        display: flex;
        flex-direction: column;
        margin-left: 197px;
        /* width: 400px; */
    }
}