@media screen and (min-width: 320px) and (max-width: 469px) {
    .howItWorks {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .container {
        width: 93%;
        margin: 0 auto;
    }

    .howItWorks__content {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
    }

    .howItWorks__content h2 {
        font-size: 58px;
        line-height: 80%;
        max-width: 275px;
        text-align: center;
    }

    .howItWorks__labels-block {
        display: flex;
        margin-top: 40px;
        margin-bottom: 20px;
        gap: 5px;
        overflow: auto;
        position: relative;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .howItWorks__labels-block::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }

    .howItWorks__label {
        border: none;
        height: 31px;
        padding-left: 10px;
        padding-right: 10px;
        color: #7A7A7A;
        font-size: 16px;
        line-height: 100%;
        background-color: #EEEEEE;
        border-radius: 18px;
        /* min-width: 150px; */
        cursor: pointer;
    }

    .howItWorks__labels-block .howItWorks__label:first-child {
        min-width: 127px;
    }

    .howItWorks__labels-block .howItWorks__label:nth-child(2n) {
        min-width: 170px;
    }

    .howItWorks__labels-block .howItWorks__label:nth-child(3n) {
        min-width: 137px;
    }

    .howItWorks__slider {
        position: relative;
        width: 100%;
    }

    .howItWorks__slider-arrows {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 10px;
    }
}