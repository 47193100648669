@import url("./Mission_1280.css");
@import url("./Mission_1024.css");
@import url("./Mission_mobile.css");


@media screen and (min-width: 1600px) {
    .mission {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .container {
        width: 1480px;
        margin: 0 auto;
    }

    .mission__content {
        width: 100%;
        padding-top: 120px;
        padding-bottom: 120px;
        background-color: #EEEEEE;
        border-radius: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mission__content h2 {
        margin-bottom: 50px;
        line-height: 80%;
    }

    .mission__content p {
        font-size: 30px;
        line-height: 100%;
        max-width: 730px;
        text-align: center;
        margin-top: 70px;
    }

    .mission__slider {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 70px;
    }

    .mission__iphoneMockup {
        background: url('../../Media/Missioniphone.png') center no-repeat;
        background-size: contain;
        width: 334px;
        height: 675px;
        filter: drop-shadow(43.8731px 59.0617px 7.70775px rgba(0, 0, 0, 0.18));
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .mission__like-btn {
        position: absolute;
        right: 0px;
        bottom: 30px;
    }

    .mission__slider-block {
        height: 642px;
        width: 298px;
    }

    .mission__slide {
        height: 642px;
        width: 298px;
    }

    .mission__slide img {
        height: 642px;
        width: 298px;
        pointer-events: none;
    }
}